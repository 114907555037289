import {
  default as MuiTableFooter,
  type TableFooterProps,
} from '@mui/material/TableFooter';

export type { TableFooterProps };

export default function TableFooter({
  className = '',
  ...props
}: TableFooterProps) {
  return <MuiTableFooter className={className} {...props} />;
}
