import { twMerge } from 'tailwind-merge';
import {
  default as MuiTableRow,
  type TableRowProps as MuiTableRowProps,
} from '@mui/material/TableRow';
import { classes, styles } from './utils';

export interface TableRowProps extends MuiTableRowProps {
  className?: string;
  onClick?: () => void;
}

export default function TableRow({ className = '', ...props }: TableRowProps) {
  const tableClasses = twMerge(classes.row, props.onClick && classes.clickable);
  return (
    <MuiTableRow
      hover={!!props.onClick}
      className={twMerge(tableClasses, className)}
      sx={styles.tableRow}
      {...props}
    />
  );
}
