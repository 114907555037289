import { twMerge } from 'tailwind-merge';
import AscendingIcon from '@/icons/AscendingIcon';
import DescendingIcon from '@/icons/DescendingIcon';
import {
  default as MuiTableCell,
  type TableCellProps as MuiTableCellProps,
} from '@mui/material/TableCell';
import { ASCENDING, DESCENDING } from '@/constants/sortOrder';
import { type SortOrder } from '@witmetrics/api-client';
import { classes, styles } from './utils';

export interface SortableHeadCellProps extends MuiTableCellProps {
  className?: string;
  align?: 'left' | 'right' | 'center';
  defaultSortOrder?: SortOrder;
  sortOrder: SortOrder | null;
  onSort: () => void;
}

export default function SortableHeadCell({
  className = '',
  align = 'left',
  defaultSortOrder = DESCENDING,
  sortOrder,
  onSort,
  children,
}: SortableHeadCellProps) {
  const getLabelClasses = () => {
    if (sortOrder) return classes.label;
    let classList = [classes.label, classes.inactiveLabel];
    if (align === 'center') {
      classList.push(classes.centerLabel);
    } else if (align === 'left') {
      classList.push(classes.leftLabel);
    } else if (align === 'right') {
      classList.push(classes.rightLabel);
    }
    return twMerge(...classList);
  };

  const getContentClasses = () => {
    let classList = [classes.content];
    if (align === 'center') {
      classList.push(classes.centerContent);
    } else if (align === 'left') {
      classList.push(classes.leftContent);
    } else if (align === 'right') {
      classList.push(classes.rightContent);
    }
    return twMerge(...classList);
  };

  const renderArrow = () => {
    const activeProps = {
      className: classes.arrow,
      fontSize: 'small',
    } as const;
    const inactiveProps = {
      className: twMerge(classes.arrow, classes.inactiveArrow),
      fontSize: 'small',
    } as const;
    if (sortOrder === DESCENDING) {
      return <DescendingIcon {...activeProps} />;
    } else if (sortOrder === ASCENDING) {
      return <AscendingIcon {...activeProps} />;
    }
    return defaultSortOrder === DESCENDING ? (
      <DescendingIcon {...inactiveProps} />
    ) : (
      <AscendingIcon {...inactiveProps} />
    );
  };

  return (
    <MuiTableCell
      className={twMerge(classes.cell, sortOrder && classes.active, className)}
      sx={styles.cell}
      onClick={() => onSort()}>
      <div className={getContentClasses()}>
        <label className={getLabelClasses()}>{children}</label>
        {renderArrow()}
      </div>
    </MuiTableCell>
  );
}
