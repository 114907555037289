export const classes = {
  tableCell: 'py-0 px-0',
  skeleton: 'py-6',
};

export const styles = {
  skeleton: (delay: number, duration: number) => ({
    '&:after': {
      animationDelay: `${delay}s`,
      animationDuration: `${duration}s`,
    },
  }),
};
