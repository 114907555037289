import {
  default as MuiTableBody,
  type TableBodyProps as MuiTableBodyProps,
} from '@mui/material/TableBody';

export interface TableBodyProps extends MuiTableBodyProps {
  className?: string;
}

export default function TableBody({
  className = '',
  ...props
}: TableBodyProps) {
  return <MuiTableBody className={className} {...props} />;
}
