import ExcelJS from 'exceljs';

const PADDING = 2;

export function exportArraysToSpreadsheet({
  data,
  sheetName,
  filename,
}: {
  data: any[];
  sheetName: string;
  filename: string;
}) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);
  worksheet.addRows(data);
  return downloadSpreadsheet(filename, workbook);
}

export function exportSpreadsheet<T extends object>({
  data = [],
  sheetName,
  filename,
}: {
  data: T[];
  sheetName: string;
  filename: string;
}) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);
  if (data.length > 0) {
    const columnKeys = Object.keys(data[0]) as (keyof T)[];
    // Define the columns for ExcelJS -- allows rows to reference keys
    worksheet.columns = columnKeys.map((key) => getColumnSettings(key, data));
    // Add a row for each data entry
    worksheet.addRows(data);
  }
  return downloadSpreadsheet(filename, workbook);
}

async function downloadSpreadsheet(
  filename: string,
  workbook: ExcelJS.Workbook
) {
  const bufferData = await workbook.xlsx.writeBuffer();
  const url = window.URL.createObjectURL(
    new Blob([bufferData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
  );
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${filename}.xlsx`;
  anchor.click();
  window.URL.revokeObjectURL(url);
}

function getColumnSettings<T extends object>(columnKey: keyof T, data: T[]) {
  return {
    key: columnKey,
    header: columnKey,
    width: getColumnWidth(columnKey, data),
  } as ExcelJS.Column;
}

function getColumnWidth<T extends object>(columnKey: keyof T, data: T[]) {
  return data
    .map((d) => d[columnKey])
    .reduce((a, b) => {
      const width = getValueWidth(b);
      return width > a ? width : a;
    }, getValueWidth(columnKey));
}

function getValueWidth(value: any) {
  return value ? `${value}`.length + PADDING : 0;
}
