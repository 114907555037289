import { twMerge } from 'tailwind-merge';
import {
  default as MuiTableHead,
  type TableHeadProps as MuiTableHeadProps,
} from '@mui/material/TableHead';
import { classes } from './utils';

export interface TableHeadProps extends MuiTableHeadProps {
  className?: string;
}

export default function TableHead({
  className = '',
  ...props
}: TableHeadProps) {
  return (
    <MuiTableHead
      className={twMerge(classes.tableHead, className)}
      {...props}
    />
  );
}
