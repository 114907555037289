import { type ReactNode, useRef } from 'react';
import DefaultOptionsMenu from '@/components/DefaultOptionsMenu';
import OptionsIcon from '@/icons/OptionsIcon';
import TableCell, { type TableCellProps } from '../TableCell';
import { useToggle } from '@/hooks/useToggle';
import { classes } from './utils';

export interface OptionsCellProps extends TableCellProps {
  extraOptions?: ReactNode;
  onEdit: () => void;
  onDelete: () => void;
}

export default function OptionsCell({
  onEdit,
  onDelete,
  extraOptions,
  ...props
}: OptionsCellProps) {
  const menuToggleRef = useRef(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const handleEdit = () => {
    onEdit();
    toggleMenu();
  };

  const handleDelete = () => {
    onDelete();
    toggleMenu();
  };

  return (
    <TableCell {...props}>
      <div className={classes.optionsWrapper}>
        <OptionsIcon
          ref={menuToggleRef}
          className={classes.optionsIcon}
          // @ts-ignore -- MuiIcon not recognizing custom palette
          color="grey-6"
          onClick={() => toggleMenu()}
        />
        <DefaultOptionsMenu
          open={isMenuOpen}
          anchorTo={menuToggleRef.current}
          extraOptions={extraOptions}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onClose={() => toggleMenu()}
        />
      </div>
    </TableCell>
  );
}
