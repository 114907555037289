import { twMerge } from 'tailwind-merge';
import {
  default as MuiTableCell,
  type TableCellProps as MuiTableCellProps,
} from '@mui/material/TableCell';
import { classes } from './utils';

export interface TableHeadCellProps extends MuiTableCellProps {
  className?: string;
}

export default function TableHeadCell({
  className = '',
  ...props
}: TableHeadCellProps) {
  return (
    <MuiTableCell className={twMerge(classes.cell, className)} {...props} />
  );
}
