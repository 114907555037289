import TableContainer from '@mui/material/TableContainer';
import {
  default as MuiTable,
  type TableProps as MuiTableProps,
} from '@mui/material/Table';

export interface TableProps extends MuiTableProps {
  className?: string;
}

export default function Table({ className = '', ...props }: TableProps) {
  return (
    <TableContainer className={className}>
      <MuiTable stickyHeader {...props} />
    </TableContainer>
  );
}

export { default as TableBody } from './TableBody';
export { default as TableCell } from './TableCell';
export { default as TableHead } from './TableHead';
export { default as TableHeadCell } from './TableHeadCell';
export { default as SortableHeadCell } from './SortableHeadCell';
export { default as TableHeadRow } from './TableHeadRow';
export { default as TableRow } from './TableRow';
export { default as TableFooter } from './TableFooter';
export { default as OptionsCell } from './OptionsCell';
export { default as SkeletonRow } from './SkeletonRow';
export { default as SkeletonRows } from './SkeletonRows';
