import SkeletonRow from '../SkeletonRow';

export type SkeletonRowsProps = {
  rows?: number;
  columns?: number;
};

export default function SkeletonRows({
  rows = 10,
  columns = 1,
}: SkeletonRowsProps) {
  const delays = Array.from({ length: rows }, (_, i) => i / 10);
  return (
    <>
      {delays.map((delay) => (
        <SkeletonRow key={delay} size={columns} delay={delay} />
      ))}
    </>
  );
}
