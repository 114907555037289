import {
  default as MuiTableRow,
  type TableRowProps as MuiTableRowProps,
} from '@mui/material/TableRow';

export interface TableRowProps extends MuiTableRowProps {
  className?: string;
}

export default function TableHeadRow({
  className = '',
  ...props
}: TableRowProps) {
  return <MuiTableRow className={className} {...props} />;
}
