export const classes = {
  cell: 'cursor-pointer text-sm text-grey-7 font-medium py-4 bg-grey-1 border-b border-grey-3 transition-colors',
  active: 'font-semibold text-purple-2',
  content: 'w-full inline-flex items-center',
  leftContent: 'justify-start',
  centerContent: 'justify-center',
  rightContent: 'justify-end',
  label: 'relative cursor-pointer select-none transition-all',
  arrow: 'relative cursor-pointer h-[1.5rem] w-[1.5rem] transition-all',
  centerLabel: 'center-label text-center',
  leftLabel: 'text-left',
  rightLabel: 'text-right',
  inactiveLabel: 'inactive-label hover:text-grey-8',
  inactiveArrow: 'inactive-arrow hover:text-grey-8',
};

export const styles = {
  cell: {
    '& .inactive-arrow': {
      left: '-1.5rem',
      opacity: 0,
    },
    '&:hover .inactive-arrow': {
      left: 0,
      opacity: 1,
    },
    '& .inactive-label.center-label': {
      left: '0.75rem',
    },
    '&:hover .inactive-label.center-label': {
      left: 0,
    },
  },
};
