import TableCell from '../TableCell';
import TableRow, { type TableRowProps } from '../TableRow';
import Skeleton from '@mui/material/Skeleton';
import { classes, styles } from './utils';

export interface SkeletonRowProps extends TableRowProps {
  delay?: number;
  duration?: number;
  size?: number;
}

export default function SkeletonRow({
  delay = 0.2,
  duration = 1.2,
  size = 1,
  ...props
}: SkeletonRowProps) {
  return (
    <TableRow {...props}>
      <TableCell colSpan={size} className={classes.tableCell}>
        <Skeleton
          className={classes.skeleton}
          variant="rectangular"
          animation="wave"
          sx={styles.skeleton(delay, duration)}
        />
      </TableCell>
    </TableRow>
  );
}
